<template>
  <title-bar :title="$t('navigations.emailTemplates')"/>
  <div>
    <div v-if="rows" class="custom-table">
      <table-actions
        :actions="['perPage']"
        @perPageChange="perPageChange"
        :defaultPerPage="perPage"
      />
      <table-lite
        :is-slot-mode="true"
        :is-loading="isLoading"
        :columns="columns"
        :rows="rows"
        @is-finished="isLoading = false"
      >
        <template v-slot:name="data">
          {{ $helper.getEnumTranslation("EMAIL_TEMPLATE_NAME", data.value.name, $i18n.locale) }}
        </template>
        <template v-slot:notificationEmails="data">
          <div class="vs__selected-options"
               v-if="data.value.notificationEmails && data.value.notificationEmails.length">
            <span class="vs__selected" v-for="email in data.value.notificationEmails" :key="email">
              {{ email }}
            </span>
          </div>
        </template>
        <template v-slot:owner="data">
          <b-badge :variant="data.value.owner ? 'success' : 'danger'">
            {{ data.value.owner ? $t('forms.yes').toUpperCase() : $t('forms.no').toUpperCase() }}
          </b-badge>
        </template>
        <template v-slot:onOff="data">
          <b-badge :variant="data.value.onOff ? 'success' : 'danger'">
            {{ data.value.onOff ? $t('forms.yes').toUpperCase() : $t('forms.no').toUpperCase() }}
          </b-badge>
        </template>
        <template v-slot:actions="data">
          <div class="just-buttons">
            <edit-btn
                v-if="canUpdate"
                :path="$helper.getEditUrl('email/templates', data.value.id)"
            />
          </div>
        </template>
      </table-lite>
      <table-pagination
        v-model="currentPage"
        :total="pages"
        :per-page="perPage"
        @input="onChangePage"
      />
    </div>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import TableActions from "@/components/Table/TableActions.vue";
import EditBtn from "@/components/Buttons/EditBtn.vue";
import ResourceUtils from "@/mixins/ResourceUtils";

export default {
  components: {
    TitleBar,
    TableLite,
    TablePagination,
    TableActions,
    EditBtn
  },
  mixins: [ResourceUtils],
  data() {
    return {
      isLoading: false,
      rows: null,
      perPage: this.$store.state.defaultPerPage,
      currentPage: 1,
      pages: 0,
      canUpdate: false,
      columns: [
        {
          label: this.$t("forms.name"),
          field: "name"
        },
        {
          label: this.$t("forms.notificationEmails"),
          field: "notificationEmails"
        },
        {
          label: this.$t("forms.owner"),
          field: "owner"
        },
        {
          label: this.$t("forms.enabled"),
          field: "onOff"
        },
        {
          label: this.$t("forms.actions"),
          field: "actions",
          width: "120px",
        },
      ],
    };
  },
  created() {
    this.setPermissions();
    this.load();
  },
  methods: {
    load() {
      const params = {
        itemsPerPage: this.perPage,
        page: this.currentPage,
      };
      this.$EmailTemplates.getCollection({params}).then((response) => {
        this.pages = response.data["hydra:totalItems"];
        this.rows = response.data["hydra:member"];
      });
    },
    setPermissions() {
      this.canUpdate = this.$helper.userCapability('UPDATE', 'EMAIL_TEMPLATES')
    },
    onChangePage(page) {
      this.currentPage = page;
      this.load();
    },
    onPageChange() {
      this.load();
    },
    perPageChange(num) {
      this.currentPage = 1;
      this.perPage = num;
      this.load();
    },
    error() {
      this.$emit("clearAction");
    },
  },
}
</script>
